import React from "react";
import NumberFormat from "react-number-format";

function TopPosition(props) {
    return (
        <tr>
            <td>{props.position}.</td>
            <td><b>{props.title}</b></td>
            <td>(<NumberFormat value={props.points} displayType={'text'} thousandSeparator=" "/>) + {props.extraPoints}</td>
        </tr>
    )
}

export default TopPosition;