import React from "react";

function Page(props) {
    let pageProps = {
        onClick: (e) => props.goToPage(props.page),
        className: 'page',
        style: {
            cursor: 'pointer'
        }
    };

    if (props.currentPage === props.page) {
        pageProps.id = 'pushed';
        pageProps.style.cursor = 'default';
    }

    return <a {...pageProps}>{props.page}</a>
}

export default Page;
