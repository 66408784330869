import React from "react";
import TopPosition from "./TopPosition";

function TopCategory(props) {
    console.log(props);
    return (
        <div className="blokas" style={{textAlign: 'left'}}>
            <table style={{padding: '3px 0px 3px 0px'}}>
                <tbody>
                    <tr>
                        <td id="sub_line"/>
                        <td className="title_text"><span>{props.title}</span></td>
                        <td id="sub_line"/>
                    </tr>
                </tbody>
            </table>
            <table>
                <tbody>
                {props.data.map((row, index) => <TopPosition title={row.nick}
                                                             key={index}
                                                             points={row.points}
                                                             position={index+1}
                                                             extraPoints={row.extra_points}/>)}
                </tbody>
            </table>
        </div>
    )
}

export default TopCategory;