import React from "react";
import Page from "./Page";

function Pagination(props)
{
    return (
        <>
            {props.total > 1 && <Page page="1"
                                      key="1"
                                      goToPage={props.goToPage}
                                      currentPage={props.page}/>}
            {props.page - 1 > 2 && <small>...</small>}
            {[...Array(props.total).keys()].map( i => {
                i++;

                if (i < 2 || i === props.total || i  < props.page - 1 || i > props.page + 1) {
                    return null;
                }

                return <Page page={i}
                             key={i}
                             goToPage={props.goToPage}
                             currentPage={props.page}/>;
            })}
            {props.page +1 < props.total - 1 && <small>...</small>}
            {props.total > 1 && <Page page={props.total}
                                      key={props.total}
                                      goToPage={props.goToPage}
                                      currentPage={props.page}/>}
        </>
    )
}

export default Pagination;