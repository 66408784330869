import React from 'react';
import icon from '../images/pranesejas.gif';

function Informator(props) {
    return (
        <table className="pranesejas">
            <tbody>
            <tr>
                <td><img src={icon} alt=""/></td>
                <td><span id="first">
                    {props.children}
                </span>
                </td>
            </tr>
            </tbody>
        </table>
    )
}

export default Informator;