import React from "react";
import mod10 from '../../images/mod10.gif';
import mod5 from '../../images/mod5.gif';

function BestPlayer(props) {
    return (
        <div className="blokas" style={{textAlign: 'center'}}>
            <table style={{padding: '3px 0px 3px 0px'}}>
                <tbody>
                <tr>
                    <td id="sub_line"/>
                    <td className="title_text"><span>GERIAUSIAS SERVERIO ŽAIDĖJAS</span></td>
                    <td id="sub_line"/>
                </tr>
                </tbody>
            </table>
            <div style={{textAlign: 'center'}}>
                <img src={mod10}
                     alt=""
                     style={{marginBottom: '-10px'}}/>
                <div style={{fontSize: '28px', color: 'yellow'}}>
                    1. {props.data[0].nick} - {props.data[0].points}
                </div>
            </div>
            <div style={{textAlign: 'center'}}>
                <img src={mod5}
                     alt=""
                     style={{marginBottom: '-10px'}}/>
                <div style={{fontSize: '20px', color: 'silver'}}>2. {props.data[1].nick} - {props.data[1].points}</div>
            </div>
            <div style={{textAlign: 'center'}}>
                <img src={mod5}
                     alt=""
                     style={{marginBottom: '-10px'}}/>
                <div style={{fontSize: '18px', color: '#cd7f32'}}>3. {props.data[2].nick} - {props.data[2].points}</div>
            </div>
            <div className="line">&nbsp;</div>
            <div style={{textAlign: 'center'}}>
                <div style={{fontSize: '14px', color: '#fff'}}>4. {props.data[3].nick} - {props.data[3].points}</div>
            </div>
            <div style={{textAlign: 'center'}}>
                <div style={{fontSize: '14px', color: '#fff'}}>5. {props.data[4].nick} - {props.data[4].points}</div>
            </div>
        </div>
    )
}

export default BestPlayer;